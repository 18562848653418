import React from 'react';

function Icon(props) {
  return (
    <svg x="0px" y="0px"
    viewBox="0 0 210 210">
      <path className="icon" d="M199.8,52c-5.5-10.8-13.8-19.9-24.1-26.3c-10.6-6.8-23.7-10.2-38.8-10.2H98.4c-7,0-13,1.6-17.9,4.7
        c-4.7,2.9-8.6,7.1-11.1,12.1c-2.4,4.8-3.7,10.1-3.7,15.5c0,0.7,0,1.4,0.1,2.1c-6.8,0-12.8,1.6-17.6,4.7c-4.7,2.9-8.6,7.1-11.1,12.1
        c-2.4,4.8-3.7,10.1-3.7,15.5c0,0.7,0,1.3,0.1,2c-5.8,0-11.5,1.7-16.5,4.7C12.3,91.8,8.4,96,5.9,101c-4.9,9.7-4.9,21.3,0,31
        c2.5,5,6.4,9.1,11.1,12c4.9,3.1,10.9,4.7,17.9,4.7h52c0.2,0.4,48.8,44.6,48.8,44.6c1.7,1.6,4.3,1.5,5.9-0.2c0.7-0.8,1.1-1.8,1.1-2.9
        v-24.5l0,0V74.2l-28.3-20.8l50.7,10.4v87c3.7-1.4,7.2-3.2,10.5-5.3c10.3-6.4,18.6-15.5,24.1-26.3C210.5,98.1,210.5,73.1,199.8,52
        L199.8,52z"/>
    </svg>
  );
}

export default Icon;