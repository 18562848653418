import { useEffect, useState } from 'react';
import React from 'react';

import { 
  FloatButton, 
  Typography, 
  Statistic, 
  Drawer, 
  Button, 
  Input, 
  Form, 
  List,  
  Spin, 
  Col, 
  Row, 
} from 'antd';
import { 
  FireOutlined, 
  FireFilled, 
  AimOutlined, 
  SendOutlined, 
  ShareAltOutlined,
  ShopFilled,
} from '@ant-design/icons';

import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import { Icon } from 'leaflet';

import { ApiCallPost } from '../helpers/Webservice';
import ServicesDrawer from './ServicesDrawer';
import Candle from './Candle';

const { Paragraph } = Typography;

const cemetery_token = window.location.host == "localhost:3000" ? "caiazzo" : window.location.host.split(".")[0].replace(/^https?:\/\//, '');
var urlWebsite = window.location.protocol + "//" + window.location.host;

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
};

function convertDate(date){
  var onlyDate = date.split(" ")[0];
  var onlyHour = date.split(" ")[1];

  var day = onlyDate.split("-")[2];
  var month = onlyDate.split("-")[1];
  var year = onlyDate.split("-")[0];

  return(`${day}-${month}-${year}`)

};

function coordinatesToArray(coordinates){
  var arr = [];
  var long = coordinates.split(",")[0];
  var lat = coordinates.split(",")[1];
  
  if(coordinates.search("'") != -1){
    return [41.18033070066165, 14.357228515645536];
  }else{
    arr.push(long, lat);
    return arr;
  }
}

function DeceasedModal({data}) {

  const [likesList, setLikesList] = useState(localStorage.getItem("__cimiterodigitale__tomb_like") ? localStorage.getItem("__cimiterodigitale__tomb_like") : []);
  const [refreshComments, setRefreshComments] = useState(0);
  const [deceasedData, setDeceasedData] = useState({});
  const [tombLikes, setTombLikes] = useState(0);
  const [showMap, setShowMap] = useState(false);
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState([]);
  const [render, setRender] = useState(false);
  const [counter, setCounter] = useState(20);
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();

  //gestione del drawer servizi
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };


  //recupero i dati del morto
  useEffect(()=>{
    setRender(false);

    const body = {
      cemetery_token,
      "deceased_id":data.id
    };

    (async()=>{
      const res = await  ApiCallPost("deceased_get",body);
  
      if(res.response == "ok"){
        setDeceasedData(res.results[0]);
        setTombLikes(res.results[0].deceased_gifts);
        setComments(res.results[0].prayers);
        setRender(true);
      }else{
        window.location.replace("/");
        console.log(res);
      }
    })()

  },[data.id])


  const handleShareButton = () => {
    // Check if navigator.share is supported by the browser
    if (navigator.share) {
      console.log("Congrats! Your browser supports Web Share API");
      navigator
        .share({
          url: `${urlWebsite}?id=${data.id}`
        })
        .then(() => {
          console.log("Condivisione riuscita");
        })
        .catch(() => {
          console.log("Condivisione fallita");
        });
    } else {
      console.log("Sorry! Your browser does not support Web Share API");
    }
  };

  //recupero i commenti e li aggiorno
  useEffect(()=>{

    if(refreshComments == 0) return;

    const body = {
      cemetery_token,
      "deceased_id":data.id
    };

    (async()=>{
      const res = await  ApiCallPost("deceased_get",body);
      
      if(res){
        setComments(res.results[0].prayers);
        form.resetFields();
      }else{
        console.log(res);
      }
    })()

  },[refreshComments])

  //recupero anche la likeslist ed i likes
  useEffect(() => {
    //inizializzo la lista recuperandola dal sessionStorage
    if (localStorage.getItem("__cimiterodigitale__tomb_like")){
      setLikesList(JSON.parse(localStorage.getItem("__cimiterodigitale__tomb_like")))
    }
    setCounter(counter + 20);
  }, []);

  //lascia un commento
  const onFinish = (values) => {

    const body = {
      cemetery_token,
      "deceased_id":data.id,
      "friend_name":values.name,
      "phrase":values.text
    };

    //invio il commento
    (async()=>{
      const res = await  ApiCallPost("deceased_prayer_set",body);
      
      if(res.response == "ok"){
        setRefreshComments(refreshComments + 1);
      }else{
        console.log(res);
      }
    })()

  };

  //lascia un like
  const leaveLike = (event) =>{

      var operation = "+";
      var index = likesList.indexOf(data.id); //controllo se nel local storage ho già lasciato like a questa tomba

      if(likesList.length && index > -1 ) operation = "-"; //se ho lasciato like l'operazione è di sottrazione
      if(tombLikes < 1 && operation == "-") return; //se la tomba è a 0 likes non faccio sottrarre

      const body = {
        cemetery_token,
        "deceased_id":data.id,
        "operation":operation,
      };

      (async()=>{
          const res = await  ApiCallPost("deceased_gift_set",body);
          
          if(res.response == "ok"){

            var templikesList = likesList;
            
            //se ho mandato l'addizione del likes aggiungo l'id al sessionStorage e aggiungo a schermo il like
            if(operation == "+"){
              setTombLikes(parseInt(tombLikes) + 1);
              templikesList.push(data.id);
              setLikesList(templikesList);
              localStorage.setItem("__cimiterodigitale__tomb_like", JSON.stringify(templikesList));
            //se ho mandato la sottrazione del likes tolgo l'id al sessionStorage e sottraggo a schermo il like
            }if(operation == "-"){
              setTombLikes(parseInt(tombLikes) - 1);
              templikesList.splice(likesList.indexOf(data.id), 1);
              setLikesList(templikesList);
              localStorage.setItem("__cimiterodigitale__tomb_like", JSON.stringify(templikesList));
            }else{
              return
            }

          }else{
            console.log(res);
          }
        })()
   
  }

  const markerIcon = new Icon({
    iconUrl: "/marker-icon.png",
    iconSize:[40,40]
  })

  return (
    <div className='deceased-modal-body' style={{backgroundImage:"url('/marble-background.jpg')"}}>
  
      {render ?
          <Col align={"center"}>  
              <div 
                  className='deceased-cover w-100 flex align-center' 
                  style={{background:
                    !deceasedData.tomb_picture ?  "url('/cimitero-digitale-sfondo.jpg')" : 
                    deceasedData.tomb_picture && !deceasedData.epitaph ? `url('${deceasedData.tomb_picture+`?ref=${new Date().getMilliseconds()}`}')` :
                    deceasedData.tomb_picture && deceasedData.epitaph ? `linear-gradient(0deg, rgb(0 0 0 / 30%), rgb(0 0 0 / 30%)), url('${deceasedData.tomb_picture+`?ref=${new Date().getMilliseconds()}`}')` : 
                    "url('/cimitero-digitale-sfondo.jpg')"
                  }}
                >
                <div className='deceased-epitaph w-100 text-center color-white fs-40'>{deceasedData.epitaph}</div>
              </div>
              {/* info del deceduto */}
              <div className='flex column align-center gap-10 mt-20'>
                <div className='deceased-image-wrapper'>
                  <div className='deceased-image'>
                    <img src={deceasedData.deceased_picture ? deceasedData.deceased_picture+`?ref=${new Date().getMilliseconds()}` : "/placeholder.jpg"}/>
                  </div>
                  <Candle/>
                  {/* gifts  */}
                  <div className='gift-wrapper flex gap-10 mt-10' onClick={leaveLike} >
                    <div className='deceased-gifts cursor-pointer flex column align-center'>
                      <Statistic 
                        value={tombLikes} 
                        prefix={
                          likesList.indexOf(data.id) != -1 ? <FireFilled className='gift-icon'/> : <FireOutlined className='gift-icon'/> 
                        } 
                      />
                      <span className='color-grey fs-12'>Affetti Lasciati</span>
                    </div>
                  </div>

                </div>
                <div className='flex'>
                  <div className='deceased-name bold flex gap-10 align-center flex-wrap'>
                    {deceasedData.surname + " " + deceasedData.name}
                  </div>
                </div>
                <div className='deceased-date text-center fs-15 flex column bold'>
                  <span className='opacity-08  fs-18 mb-10'>{ deceasedData.birthdate ? convertDate(deceasedData.birthdate) : "Assente"} | {deceasedData.deathdate ? convertDate(deceasedData.deathdate) : "Assente"}</span>
                  <Button size='large' type={"primary"} onClick={() => setShowMap(!showMap)}>
                    <AimOutlined /> Posizione: {deceasedData.sector_name + " | Blocco " + deceasedData.block_name + " | Tomba " + deceasedData.tomb_reference}
                  </Button>
                </div>

                <div className='flex flex-wrap gap-10 justify-center mb-20 deceased-actions p-10'>
                  {deceasedData.coordinates ?
                      <a target="_blank" href={`http://www.google.com/maps/place/${deceasedData.coordinates}`}>
                        <Button size='large' type={"primary"} block ><SendOutlined /> Naviga </Button>
                      </a>
                    :
                      ""
                  }
                  {deceasedData.matterport_link ?
                      <a target="_blank" href={deceasedData.matterport_link}>
                        <Button size='large' type={"primary"} block className='flex gap-5 align-center justify-center' ><img src="/cardboard-vr.svg" width={20}/> Visita Virtuale</Button>
                      </a>
                    :
                      ""
                  }
                  <a>
                    <Button  size='large' onClick={showDrawer} block type={"primary"}><ShopFilled /> Servizi </Button>
                  </a>
                  <a>
                    <Button size='large' onClick={handleShareButton} block type={"primary"}><ShareAltOutlined /> Condividi </Button>
                  </a>
                </div>
              </div>

              {deceasedData.coordinates && showMap ?
                  <MapContainer 
                    attributionControl={false}  
                    className='deceased-map' 
                    center={coordinatesToArray(deceasedData.coordinates)} 
                    zoom={55}
                  >
                    <TileLayer
                      url='https://www.google.cn/maps/vt/lyrs=s&?x={x}&y={y}&z={z}'
                    />
                    <Marker 
                      position={coordinatesToArray(deceasedData.coordinates)} 
                      icon={markerIcon}
                    />
                  </MapContainer>
                :
                  ""
              }
              {/* info del deceduto */}

              <span className='fw-500 fs-18'>{comments.length > 0 ? "Pensieri" : "Lascia un pensiero"}</span>

              {/* box commenti */}
              {comments.length > 0 && 
                <div
                  id="commentsWrapper"
                >
                  <List
                    dataSource={comments}
                    renderItem={(item) => (
                    <div className='comment' key={item.id}>
                        <div className='comment-name bold fs-16'>{item.friend_name}</div>
                        <div className='comment-body mb-10'>{item.phrase}</div>
                        <div className='comment-body fs-12 color-light-grey'>Data: {convertDate(item.created_at)}</div>
                    </div>
                    )}
                  />
                </div>
              } 
              {/* box commenti */}

              {/* form di invio commento */}
              <div className='comment-form-wrapper'>
                <Form
                  form={form}
                  {...layout}
                  name="comment-form"
                  onFinish={onFinish}
                >
                  <Form.Item
                    name={'name'}
                    label="Nome"
                    rules={[
                      {
                        required:true,
                        message:"Inserisci il tuo Nome"
                      },
                    ]}
                  >
                    <Input maxLength={20} placeholder="Mario Rossi"/>
                  </Form.Item>
                  <Form.Item 
                    name={"text"} 
                    label="Pensiero"
                    rules={[
                      {
                        type: 'text',
                        required:true,
                        message:"È obbligatorio lasciare un pensiero",
                      },
                    ]}
                  >
                    <Input.TextArea maxLength={300} placeholder="Max. 300 caratteri"/>
                  </Form.Item>
                  <Form.Item
                    wrapperCol={{
                    span:24
                    }}
                  >
                    <Button className='mt-10' block type="primary" size='large' htmlType="submit">
                      <span className='bold'>Invia</span>
                    </Button>
                  </Form.Item>
                </Form>
              </div>
              {/* form di invio commento */}

            {/* drawer dei servizi */}
            <Drawer
              title={<><ShopFilled style={{marginRight:"10px"}}/>Servizi</>}
              style={{background:"#f6f9fa"}}
              placement="bottom"
              onClose={onClose}
              height={"100%"}
              open={open}
              destroyOnClose
              key="Services Drawer"
              className='services-drawer'
            >
              <ServicesDrawer deceasedData={deceasedData}/>
            </Drawer>
            {/* drawer dei servizi */}
                    
                    
          </Col>
        :
          <div className='vh-100 flex align-center justify-center w-100 h-100'>
            <Spin/>
          </div>
      }
    </div>
  );
}

export default DeceasedModal;