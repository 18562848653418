import React, { useContext, useState } from 'react';

import { Table, Modal, Pagination, Col } from 'antd';
import DeceasedModal from './DeceasedModal';
import { Context } from '../helpers/Context';



function convertDate(date){
  var onlyDate = date.split(" ")[0];
  var onlyHour = date.split(" ")[1];

  var day = onlyDate.split("-")[2];
  var month = onlyDate.split("-")[1];
  var year = onlyDate.split("-")[0];

  return(`${day}-${month}-${year}`)

};

const columns = [
  {
    title: 'Nome',
    dataIndex: 'surname',
    responsive: ["xs","sm","md"],
  },
  {
    title: 'Data Decesso',
    dataIndex: 'deathdate',
    render: (date) => <>{date && convertDate(date)}</>,
  },
  {
    title: 'Data di Nascita',
    dataIndex: 'birthdate',
    render: (date) => <>{date && convertDate(date)}</>,
  },
  {
    title: 'Settore',
    dataIndex: 'sector_name',
    responsive: ["md","xl"],
  },
  {
    title: 'Blocco',
    dataIndex: 'block_name',
    responsive: ["xl"],
  },
];

function ResultsTable({data, page, rowsPerPage, totalRows, onPageChange}) {


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});

  const {setDeceasedModalOpen} = useContext(Context);

  const showModal = () => {
    setDeceasedModalOpen(true);
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setDeceasedModalOpen(false);
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    document.getElementsByTagName("body")[0].style.overflowY = "auto";
    setDeceasedModalOpen(false);
    setIsModalOpen(false);
  };
  const onClick = (record) =>{
    setModalData(record);
    document.getElementsByTagName("body")[0].style.overflowY = "hidden";
    showModal();
  }
  //componente paginazione
  const TablePagination=()=>{
    return(
     <>
      <Pagination  
          locale={"Righe per pagina"}
          pageSize={rowsPerPage}
          hideOnSinglePage
          showSizeChanger={false}
          defaultCurrent={page + 1}
          current={page + 1}
          total={totalRows}
          onChange={onPageChange}
        />
     </>
    )
  }

  return(
    <>
      <div className='flex column w-100 align-center'>
        <Table 
          className='results-table'
          pageSize={rowsPerPage}
          pagination={false}
          total={totalRows}
          dataSource={data} 
          columns={columns} 
          onRow={(record, rowIndex) => {
            return {
              onClick: () => onClick(record)
            };
          }}
        />  
        <div className='mt-20'>
          <TablePagination/>  
        </div>
        <Modal className='deceased-modal-wrapper' destroyOnClose  open={isModalOpen} footer={false} onOk={handleOk} onCancel={handleCancel}>
          <DeceasedModal data={modalData}/>
        </Modal>
      </div>
    </>
  );
}

export default ResultsTable;