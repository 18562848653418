import React, { useContext, useEffect, useState } from 'react';

import { CheckCircleOutlined, ShopFilled, PhoneOutlined, MailOutlined,UserOutlined } from '@ant-design/icons';
import { useElements, useStripe, PaymentElement, CardElement } from '@stripe/react-stripe-js';
import { Alert, Button, Card, Input, Timeline, message } from 'antd';
import { Context } from '../helpers/Context';
import { ApiCallPost } from '../helpers/Webservice';


function PaymentForm(props) {


  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [timeLeft, setTimeLeft] = useState(5);
  const [error, setError] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');

  const {clientSecret, cart, totalCart, setStripeModalOpen, clearCart, cemetery_token} = useContext(Context);

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.location.href,
      },
      redirect:"if_required"
    });

    if (result.error) {
      console.log(result);
      setError(result.error.message);
      setLoading(false);
    } else {

      const body = {
        "merchant_business_name":cart[0].merchant_business_name,
        "merchant_id":cart[0].merchant_id,
        "cemetery_token":cemetery_token,
        "payment_type":'stripe',
        "customer_email":email,
        "customer_phone":phone,
        "customer_name":name,
        "order_rows":cart
      };
      
      //se il pagamento va a buon fine chiamo il set_order
      (async()=>{
        const res = await  ApiCallPost("order_set",body);
        //se il set oder va a buon fine chiudo tutto e svuoto il carrello
        if(res && res.response == "ok"){

          setLoading(false);
          setSuccess(true);
          setTimeout(()=>{
            setStripeModalOpen(false);
            clearCart();
          },5000)

        }else{
          setLoading(false);

          switch (res.reasons) {
            case "cemetery_token_not_present":
              setError("Cimitero non presente");
              break;
            case "some_row_merchant_incoerent":
              setError("Alcuni prodotti sono di venditori diversi");
              break;
            case "rows_missing":
              setError("Parametri mancanti");
              break;
            case "required_fields_missing":
              setError("Campi obbligatori mancanti");
              break;
            case "incorrect_json_format":
              setError("Formato json non corretto");
              break;
          
            default:
              break;
          }
          console.log(res);
        }

      })()

    }
  };


  useEffect(() => {
    if(!success) return;
    if(timeLeft == 0) return;
    setTimeout(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
  }, [success, timeLeft]);


  return (
   <>
    {contextHolder}
    {cart.length &&  
      <form id="payment-form" className='stripe-payment-form'>
        {!success ?
          <>
            <div className='flex gap-5 color-grey fs-15 mb-20 mt-10'>
              <ShopFilled/>
              Stai acquistando presso: 
              <div className='color-black'>
                  {cart[0].merchant_business_name}
              </div>
            </div>
            <div>
              <div>Dati acquirente:</div>
              <div className='color-grey fs-12 mb-10'>I dati sono facoltativi</div>
              <div className='flex column gap-10'> 
                <Input
                  placeholder="Nome e Cognome"
                  maxLength={20}
                  onChange={(e) => setName(e.target.value)}
                  prefix={<UserOutlined className="site-form-item-icon" />}
                />
                <Input
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  maxLength={50}
                  type='email'
                  prefix={<MailOutlined className="site-form-item-icon" />}
                />
                <Input
                  placeholder="Numero di telefono"
                  type='number'
                  maxLength={11}
                  onChange={(e) => setPhone(e.target.value)}
                  prefix={<PhoneOutlined className="site-form-item-icon" />}
                />
              </div>
            </div>
            <div className='mt-20'>
              <PaymentElement/>
            </div>
            <Button size='large' loading={loading} type='primary' className='mt-20' block onClick={handleSubmit}>Paga<b>{" " + totalCart.toFixed(2)}€</b></Button>
          </>
        :
          <>
            <div className='flex gap-5 color-green fs-15 mt-10'>
              <ShopFilled/>
              Grazie per aver pagato {parseFloat(totalCart).toFixed(2)}€ a: 
              <b>
                  {cart[0].merchant_business_name}
              </b>
            </div>
            <Button size='large' disabled icon={<CheckCircleOutlined />} type='primary' className='mt-20 notice-button' block>Pagamento riuscito, chisura in {timeLeft}s</Button>
          </>
        }
        {error &&
          <Alert
            className='mt-20'
            message="Error"
            description={error}
            type="error"
            showIcon
            closable
          />
        }
      </form>
     }
   </>
  );
}

export default PaymentForm;