import React from 'react';

function Candle(props) {
  return (
    <div className='candle-wrapper'>
      <div className="holder">
        <div className="candle">
          <div className="blinking-blow"></div>
          <div className="thread"></div>
          <div className="glow"></div>
          <div className="flame"></div>
          <div className="blinking-blow shadow"></div>
        </div>
      </div>
    </div>
  );
}

export default Candle;