import React, { useEffect, useState } from 'react';

import { CloseOutlined, ShopFilled, FireFilled, DeleteOutlined, SearchOutlined} from '@ant-design/icons';
import { 
  ConfigProvider, 
  Input, 
  Row,
  Col,
  Button,
  message,
  FloatButton,
  Divider,
  Modal
} from 'antd';

import ResultsTable from './components/ResultsTable';
import { ApiCallPost } from './helpers/Webservice';
import Icon from './components/Icon';
import DeceasedModal from './components/DeceasedModal';
import { Context } from './helpers/Context';
import PaymentForm from './components/PaymentForm';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import WrapperPaymentForm from './components/PaymentForm';

//catturo il terzo livello per usarlo come token e chiamare i dati di quel cimitero, rimuovo l'http o https
const cemetery_token = window.location.host == "localhost:3000" ? "caiazzo" : window.location.host.split(".")[0].replace(/^https?:\/\//, '');
const { Search } = Input;


function App(props) {
  const [cart, setCart] = useState(sessionStorage.getItem("__cemetery_cart") ? JSON.parse(sessionStorage.getItem("__cemetery_cart")) : [])
  const [deceasedModalOpen, setDeceasedModalOpen] = useState(false); //da usare per bloccare l'overflow del body se è aperta una modal di un deceduto in concomitanza con il carrello
  const [stripeModalOpen, setStripeModalOpen] = useState(false);
  const [deceasedSurname, setDeceasedSurame] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [showResults, setShowResults] = useState(false);
  const [deceasedName, setDeceasedName] = useState("");
  const [cemeteryData, setCemeteryData] = useState({});
  const [clientSecret, setClientSecret] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const [updateCart, setUpdateCart] = useState(0);
  const [pagination, setPagination] = useState(0);  
  const [publicKey, setPublicKey] = useState("");
  const [totalCart, setTotalCart] = useState(0);
  const [disable, setDisable] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [products, setProducts] = useState([]); 
  const [render, setRender] = useState(false);
  const [intent,setIntent] = useState(false);
  const [results, setResults] = useState([]);
  const [counter, setCounter] = useState(0);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);

  const urlParams = new URLSearchParams(window.location.search);
  const deceasedId = urlParams.get('id');
  var urlWebsite = window.location.protocol + "//" + window.location.host;

  //_______________________ recupero il client secret per stripe e imposto i dati pagamento _______________________
  const createIntent = () =>{
    if(!totalCart) return;
    const body = {
      "cemetery_token":cemetery_token,
      "merchant_id":cart[0].merchant_id,
      amount:totalCart*100,
    };
    
    (async()=>{
      const res = await  ApiCallPost("stripe_payment_intents",body);
      
      if(res && res.response == "ok"){
        setClientSecret(res.results.secret_key);
        setPublicKey(res.results.public_key);
        setOpen(!open)
        setStripeModalOpen(true);
      }else{
        setOpen(false);
        messageApi.warning({
          content:"Venditore non abilitato al pagamento"
        });
      }
    })()
  }
  //_______________________ recupero il client secret per stripe e imposto i dati pagamento _______________________


  //_______________________ funzioni messaggi _______________________
  //messaggio prodotto aggiunto a carrello
  const addSuccess = () => {
    messageApi.success({
      content:"Prodotto aggiunto al carrello",
    });
  };
  //messaggio prodotto rimosso dal carrello
  const removeSuccess = () => {
    messageApi.error({
      content:"Prodotto rimosso dal carrello",
    });
  };
  //messaggio di warning limite massimo
  const merchantConflict = () =>{
    messageApi.warning({
      content:"Mi spiace non puoi aggiungere questo prodotto perché di un altro fornitore",
    });
  }
  //_______________________ funzioni messaggi _______________________

  //_______________________ Chiamata per ricevere i prodotti del cimitero _______________________
  useEffect(()=>{

    const body = {
      cemetery_token,
      "pagination":0,
      "take":400,
    };
    
    (async()=>{
      const res = await  ApiCallPost("shop_products_get",body);
      
      if(res.response == "ok"){
        setProducts(res.results);
      }else{
        console.log(res);
      }
    })()
  },[cemetery_token])
  //_______________________ Chiamata per ricevere i prodotti del cimitero _______________________

  // _______________________ chiamata per individuare i dati del cimitero _______________________
  useEffect(()=>{
    if(!cemetery_token) window.location.replace("https://www.cimiterodigitale.it");

    const body = {cemetery_token};
    //chiamo i dati del cimitero se c'è il token
    (async()=>{
      const res = await  ApiCallPost("cemetery_get",body);
      
      if(res){
        setCemeteryData(res.results[0]);
        var r = document.querySelector(':root');
        r.style.setProperty('--primary', res.results[0].cemetery_color);
        setRender(true); 
      }else{
        console.log(res);
      }
    })()
  },[cemetery_token])
  // _______________________ chiamata per individuare i dati del cimitero _______________________


  // _______________________ cambio della pagina _______________________
  const onPageChange = (pageNumber) => {
    setPage(pageNumber - 1);
  };
  // _______________________ cambio della pagina _______________________

  // _______________________ selezionare quanti risultati per pagina _______________________ 
  const onShowSizeChange = (current, pageSize) => {
    setRowsPerPage(pageSize)
  };
  // _______________________ selezionare quanti risultati per pagina _______________________ 

  // _______________________ gestione dei filtri _______________________
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  // _______________________ gestione dei filtri _______________________


  // _______________________ impostare il filtro data _______________________
  const selectDate = (date, dateString) => {
    console.log(date, dateString);
  };
  // _______________________ impostare il filtro data _______________________

  // _______________________ funzione per la ricerca _______________________

  const onSearch = () => {
    const value = searchValue.trim();

    document.getElementById("clouds").classList.add("minimize");
    document.getElementById("searchBox").classList.add("minimize");
    
     const body = {
      cemetery_token,
      "pagination":page, 
			"take":rowsPerPage,
      "name":deceasedName,
      "surname":deceasedSurname,
      "search" : value
     };

     (async()=>{
      const res = await  ApiCallPost("deceaseds_get",body);

      if(res){
        var tempData = res.results;
        for (const i in tempData) {
          tempData[i]["key"] = i;
          tempData[i]["surname"] += " " + tempData[i]["name"];
        }
        setResults(tempData);
        setTotalRows(res.results_total_rows);
        setShowResults(true);
      }else{
        console.log(res);
      }
    })()
  };

  useEffect(()=>{
    if(!render) return
    if(deceasedSurname.length < 3 && deceasedName.length < 3) {
      setShowResults(false);
      setResults([]);
      setPage(0);
      if(document.getElementById("clouds") && document.getElementById("searchBox")){
        document.getElementById("clouds").classList.remove("minimize");
        document.getElementById("searchBox").classList.remove("minimize");
      };
      return
    };
    onSearch();
  },[page])

  useEffect(()=>{
    if(deceasedName.length <= 3 && deceasedSurname.length <= 3) {
      if(document.getElementById("clouds") && document.getElementById("searchBox")){
        document.getElementById("clouds").classList.remove("minimize");
        document.getElementById("searchBox").classList.remove("minimize");
      };
      setShowResults(false);
      setResults([]);
      setPage(0);
    }else{
      return
    }
  },[deceasedName, deceasedSurname])

  //non utilizzato, solo in caso di ricerca massiva generica
  const onChange = (input) => {
    setSearchValue(input.target.value);
  }


  // _______________________ funzione per la ricerca _______________________

  // _______________________ funzioni carrello _______________________
  useEffect(()=>{
    if(sessionStorage.getItem("__cemetery_cart")){
      const tempCart = JSON.parse(sessionStorage.getItem("__cemetery_cart")); //carrello nel sessionStorage
      setCart(tempCart);


      var counter = tempCart.length; //conto i prodotti nel carrello per il badge
      var total = 0;

      tempCart.forEach(e => {
        total += parseInt(e.price);
      });

      setCounter(counter); //lo setto e lo passo ai componenti del progetto
      setTotalCart(total);
    }else return;
  },[updateCart])

  const showCart = () => {
    if(!deceasedModalOpen) document.getElementsByTagName("body")[0].style.overflowY = "hidden" ;
    setOpen(true);
  };
  const closeCart = () => {
    if(!deceasedModalOpen) document.getElementsByTagName("body")[0].style.overflowY = "auto" ;
    setOpen(false);
  };

 const addToCart = (productId, deceasedData) =>  {
    const index = products.findIndex( p => p.id == productId); //dall'id passato recupero il prodotto dalla lista
    const productToAdd = products[index]; // dati del prodotto recuperato
    productToAdd.deceased_id = deceasedData.deceased_id;
    productToAdd.quantity = 1;
    productToAdd.deceased_name = deceasedData.name + " " + deceasedData.surname;
  
  
    if(!sessionStorage.getItem("__cemetery_cart")){
      var temp = [];
      temp.push(productToAdd);
      sessionStorage.setItem("__cemetery_cart", JSON.stringify(temp));
      addSuccess();
      setUpdateCart(updateCart + 1);
    }else{
      var temp = JSON.parse(sessionStorage.getItem("__cemetery_cart"));
      var indexSameMerchant = temp.findIndex(p => p.merchant_id == productToAdd.merchant_id);
      
      if(temp[0] && temp[0].merchant_id != productToAdd.merchant_id){
        merchantConflict();
      }else{
        temp.push(productToAdd); //altrimenti lo inserisco solamente nel carrello
        sessionStorage.setItem("__cemetery_cart", JSON.stringify(temp));
        addSuccess();
      }
    }

    setUpdateCart(updateCart + 1);
  }
  
  const removeFromCart = (productId) =>{

    var temp = cart;
    var index = temp.map(p => p.idarticolo).indexOf(productId); //cerco nel carrello un prodotto con stesso id
    
    var productData = temp.splice(index, 1);
    sessionStorage.setItem("__cemetery_cart", JSON.stringify(temp));


    setUpdateCart(updateCart + 1);
    removeSuccess();
  }
  const clearCart = () => {
    sessionStorage.removeItem("__cemetery_cart");
    setCart([]);
    setUpdateCart(updateCart + 1);
    setCounter(0);
  }

  return (
    <Context.Provider
      value={
        {
          setDeceasedModalOpen,
          setStripeModalOpen,
          merchantConflict,
          cemetery_token,
          removeFromCart,
          removeSuccess,
          clientSecret,
          createIntent,
          cemeteryData,
          addSuccess,
          addToCart,
          clearCart,
          totalCart,
          products,
          cart,
        }
      }
    >
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: cemeteryData ? `${cemeteryData.cemetery_color}` : "",
          },
        }}
      >
        {contextHolder}
        {!deceasedId && render ?
          <div className="App flex column min-vh-100 bg-blue-01">
            <div className='header w-100 p-15 flex justify-end'>
            </div>
          
            {/* ------------------------ nuvole ------------------------*/}
            <div className='clouds' id='clouds'>
              <img className='cloud one' src='cloud.png'/>
              <img className='cloud two' src='cloud.png'/>
              <img className='cloud three' src='cloud.png'/>
              <img className='cloud four' src='cloud.png'/>
            </div>
            {/* ------------------------ nuvole ------------------------*/}
      
            {/* ------------------------ area di ricerca ------------------------ */}
            <div id='searchBox' className='search-box w-100 flex column align-center p-25'>
              <div className="brand-area mb-10 flex gap-10">
                <Icon/>
                <span className='cemetery-name'>
                  {cemeteryData.cemetery_name}
                </span>
              </div>
              {cemeteryData.cemetery_payoff &&
                <div className='mb-20 color-grey'>{cemeteryData.cemetery_payoff}</div> 
              }
              <div className="search-bar">
                <div
                  className='w-100 flex column gap-5'
                  onKeyDown={(e) => {
                    if(deceasedName.length >= 3 || deceasedSurname.length >= 3){
                      if(e.key == "Enter"){
                        onSearch();
                      }
                    }
                  }}
                >
                  <Input 
                    onChange={(e) => setDeceasedSurame(e.target.value.trim())}
                    size="large" 
                    placeholder="Cognome"
                    allowClear
                  />        
                  <Input 
                    onChange={(e) => setDeceasedName(e.target.value.trim())}
                    size="large" 
                    placeholder="Nome"
                    allowClear
                  />
                  <Button 
                    type="primary"
                    size="large"
                    block
                    onClick={onSearch}
                    disabled={deceasedName.length >= 3 || deceasedSurname.length >= 3 ? false : true}
                  >
                    <SearchOutlined />
                    Cerca
                  </Button>
                  <div className='cemetery-links'>
                    {cemeteryData.cemetery_link1 &&
                      <a href={cemeteryData.cemetery_link1} target='_blank'>
                        <Button block>
                          {cemeteryData.cemetery_link1_label}
                        </Button>
                      </a>
                    }
                    {cemeteryData.cemetery_link2 &&
                      <a href={cemeteryData.cemetery_link2} target='_blank'>
                        <Button block>
                          {cemeteryData.cemetery_link2_label}
                        </Button>
                      </a>
                    }
                    {cemeteryData.cemetery_link3 &&
                      <a href={cemeteryData.cemetery_link3} target='_blank'>
                        <Button block>
                          {cemeteryData.cemetery_link3_label}
                        </Button>
                      </a>
                    }
                  </div>
                </div>
              </div>
            </div>
            {/* ------------------------ area di ricerca ------------------------*/}
      
            {/* ------------------------ area dei risultati ------------------------*/}
            {showResults ?
              <div className='search-results fadeIn p-25 flex w-100 justify-center'>
                <ResultsTable 
                  onShowSizeChange={onShowSizeChange}
                  onPageChange={onPageChange}
                  rowsPerPage={rowsPerPage} 
                  totalRows={totalRows}
                  data={results} 
                  page={page}
                />
              </div>
            :
              ""
      
            }
            {/* ------------------------ area dei risultati ------------------------*/}
      
            {/* ------------------------ footer ------------------------ */}
            <div className='footer bg-primary p-25'>
              <Row gutter={[20,20]} className='align-center' justify={"center"}>
                <Col 
                  xs={{span: 24}}
                  sm={{span: 8}}
                  lg={{span: 4}}
                >
                  <div className="logo">
                    <a href="https://www.cimiterodigitale.it" target="_blank">
                      <img src="logo-white.svg"/>
                    </a>
                  </div>
                </Col>
                <Col 
                  xs={{span: 24}}
                  sm={{span: 8}}
                  lg={{span: 4}}
                >
                  <div className='flex gap-10 align-center'>
                    <img src={cemeteryData.cemetery_logo} className="cemetery-logo"/>
                    <span className='cemetery-name'>{cemeteryData.cemetery_name}</span>
                  </div>
                </Col>
                <Col 
                  xs={{span: 24}}
                  sm={{span: 8}}
                  lg={{span: 4}}
                >
                    <div className='color-white fs-18'>
                      © 2024 - {cemeteryData.cemetery_name} Powered by <a className='bold' href="https://www.cmh.it" target="_blank" style={{color:"white"}}>CMH</a>
                    </div>
                </Col>
              </Row>
            </div>
            {/* ------------------------ footer ------------------------ */}

            {/* ------------------------ cart ------------------------ */}
            <>
              <FloatButton 
               badge={{
                  count: counter,
                }}
                style={{right:"40px", bottom:"30px", zIndex:"9999"}} 
                onClick={showCart} 
                icon={<ShopFilled/>}
              />
              <div className='cart-overlay cursor-pointer' open={open ? true : false}  onClick={closeCart}/>
              <div className='cart-drawer px-10' open={open ? true : false}>
                <Button type='text' className='close-cart fs-15' shape='circle' onClick={closeCart}><CloseOutlined/></Button>
                {cart.length ?
                  <div className='cart-content flex column space-between'>
                    <div className='flex gap-5 color-grey fs-15 mb-20 mt-10'>
                      <ShopFilled/>
                      Stai acquistando presso: 
                      <div className='color-black'>
                          {cart[0].merchant_business_name}
                      </div>
                    </div>
                    <div className='cart-product-list h-100'>
                      {
                        cart.map((product)=>{
                          return(
                            <>
                              <div className='cart-product' key={product.id}>
                                <div className='flex gap-10 align-center'>
                                  <img src={product.picture} className='cart-product-image'></img>
                                  <div>
                                    <div className='cart-product-title'>{product.title}</div>
                                    <div className='fs-12'><FireFilled/> Per <b>{product.deceased_name}</b></div>
                                  </div>
                                </div>
                                <div className='flex gap-5 align-center'>
                                  <div className='cart-product-price'>{parseFloat(product.price).toFixed(2)}€</div>
                                  <Button shape='circle' icon={<DeleteOutlined/>} onClick={() => removeFromCart(product.id)}></Button>
                                </div>
                              </div>
                              <Divider/>
                            </>
                          )
                        })
                      }
                    </div>
                    <div>
                      <div className='flex space-between mb-10 mt-10 fs-18 bold'>
                        <div>
                          Totale:
                        </div>
                        <div>
                          {totalCart.toFixed(2)} €
                        </div>
                      </div>
                      <Button 
                        block 
                        size='large'
                        disabled={disable}
                        type="primary" 
                        onClick={createIntent}
                      >
                        <b>Concludi Ordine</b>
                      </Button>
                    </div>
                  </div>
                :
                  "Nessun servizio selezionato"
                }
              </div>
            </>
            {/* ------------------------ cart ------------------------*/}


            <Modal footer={false} title="Concludi ordine" className='stripe-payment-modal' onCancel={() => setStripeModalOpen(!stripeModalOpen)} open={stripeModalOpen} destroyOnClose >
              { clientSecret && publicKey &&
                <Elements stripe={loadStripe(publicKey)} options={{clientSecret}}>
                  <PaymentForm/>
                </Elements>
              }
            </Modal>

          </div>
        :
          deceasedId && render ?
          <div className='single-page-deceased h-100 vh-100 relative'>
            <Button 
              className='absolute top-10 right-10 z-index-top fs-20 color-grey' 
              shape="circle" 
              icon={<CloseOutlined />} 
              size={"large"} 
              onClick={() => {
                window.open(urlWebsite, "_self");
              }}
            />
            <DeceasedModal data={{"id":deceasedId}}/>

             {/* ------------------------ cart ------------------------ */}
             <>
              <FloatButton 
               badge={{
                  count: counter,
                }}
                style={{right:"40px", bottom:"30px", zIndex:"9999"}} 
                onClick={showCart} 
                icon={<ShopFilled/>}
              />
              <div className='cart-overlay cursor-pointer' open={open ? true : false}  onClick={closeCart}/>
              <div className='cart-drawer px-10' open={open ? true : false}>
                <Button type='text' className='close-cart fs-15' shape='circle' onClick={closeCart}><CloseOutlined/></Button>
                {cart.length ?
                  <div className='cart-content flex column space-between'>
                    <div className='flex gap-5 color-grey fs-15 mb-20 mt-10'>
                      <ShopFilled/>
                      Stai acquistando presso: 
                      <div className='color-black'>
                          {cart[0].merchant_business_name}
                      </div>
                    </div>
                    <div className='cart-product-list h-100'>
                      {
                        cart.map((product)=>{
                          return(
                            <>
                              <div className='cart-product' key={product.id}>
                                <div className='flex gap-10 align-center'>
                                  <img src={product.picture} className='cart-product-image'></img>
                                  <div>
                                    <div className='cart-product-title'>{product.title}</div>
                                    <div className='fs-12'><FireFilled/> Per <b>{product.deceased_name}</b></div>
                                  </div>
                                </div>
                                <div className='flex gap-5 align-center'>
                                  <div className='cart-product-price'>{parseFloat(product.price).toFixed(2)}€</div>
                                  <Button shape='circle' icon={<DeleteOutlined/>} onClick={() => removeFromCart(product.id)}></Button>
                                </div>
                              </div>
                              <Divider/>
                            </>
                          )
                        })
                      }
                    </div>
                    <div>
                      <div className='flex space-between mb-10 mt-10 fs-18 bold'>
                        <div>
                          Totale:
                        </div>
                        <div>
                          {totalCart.toFixed(2)} €
                        </div>
                      </div>
                      <Button 
                        block 
                        size='large'
                        disabled={disable}
                        type="primary" 
                        onClick={createIntent}
                      >
                        <b>Concludi Ordine</b>
                      </Button>
                    </div>
                  </div>
                :
                  "Nessun servizio selezionato"
                }
              </div>
            </>
            {/* ------------------------ cart ------------------------*/}

          </div> 
        :
          ""
        }
      </ConfigProvider>
    </Context.Provider>
  );
}

export default App;
