import React, { useContext } from 'react';

import { ShopFilled, CreditCardFilled } from '@ant-design/icons';
import { Avatar,Button,Card, Col, Divider, Row, Tag } from 'antd';
import Meta from 'antd/es/card/Meta';
import { Context } from '../helpers/Context';

function ServicesDrawer(props) {
  const {products, addToCart} = useContext(Context);

  return (
   <div className='services-drawer-body'>
    <Row gutter={[16,16]}>
      {products ?
          products.map((product, index) => {
            return(
              <Col 
                xs={{span:24}}
                sm={{span:12}}
                lg={{span:6}}
                xxl={{span:4}}
                key={product.id}
              >
                <Card
                  className='product-card'
                  cover={
                    <img
                      src={product.picture}
                    />
                  }
                  actions={[
                    <Button onClick={() => addToCart(product.id, props.deceasedData)} className='add-to-cart' block type='primary' icon={<CreditCardFilled/>}>Seleziona</Button>,
                  ]}
                >
                  <div className='fs-20 bold'>{product.title}</div>
                  <div className='flex column'>
                    <div className='flex'>                   
                      <div className='fs-14 color-grey'><ShopFilled style={{marginRight:"5px"}}/>
                        {product.merchant_business_name}
                      </div>
                    </div>
                  <div>
                  <Divider/>
                    <span className='fs-12'>Categoria </span>
                      <Tag className='border-none mb-5'> 
                        {product.category_title}
                      </Tag>
                  </div>
                    <div>{product.description}</div>
                    <div className='fs-20 bold'>{product.price}€</div>
                  </div>
                </Card>
              </Col>
            )
          })
        :
          "Non ci sono prodotti disponibili"
      }
    </Row>
   </div>
  );
}

export default ServicesDrawer;