import ReactDOM from 'react-dom/client';
import React from 'react';

import "leaflet/dist/leaflet.css";
import App from './App';
import './style.scss';


  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
      <App />
  );