const endpoint = "https://webservice.cimiterodigitale.it";

const ApiCallPost = async (path, body) => {
  
  try{
    
    const response = 
      await fetch(
        `${endpoint}/${path}`,
        {
          method: "POST",
          body:JSON.stringify(body)
        }
      );
    if(response){
      const json = await response.json();
      if(json.response === "ok"){
        return json;    
      }else{
        return json;
      }
    }

  }catch(_){}
  
  return null;
}

export {ApiCallPost};